<template>
  <sdCards headless class="demo__card">
    <div class="bill__wrapper">
      <BillGraphComponent :account="{ type: 'trading_accounts', id: AccountId }" />
      <div class="bill" v-if="BillAccount">
        <div class="bill__inner">
          <a-statistic
            :class="`bill__amount _${BillAccount?.mt5_account_data.kind}`"
            :value="BillAccount.amount"
            :precision="2"
          >
            <template #title>
              <router-link :to="'/trading-account/' + AccountId + '/page/1'">
                <div class="bill__title">
                  <sdFeatherIcons class="bill__icon" :type="BillIcon" size="14" />
                  <p class="bill__title-hidden">
                    {{ BillAccount?.mt5_account_data.name }}
                  </p>
                  <a-tooltip placement="right" :title="i18n.t('accounts.trading.leverage')">
                    (1:{{ BillAccount?.mt5_account_data.leverage }})
                  </a-tooltip>
                </div>
              </router-link>
            </template>
            <template #suffix>
              <p class="bill__currency">USDT</p>
            </template>
          </a-statistic>
        </div>
        <div class="bill__buttons bill__inner" v-if="isBlocked">
          <sdButton
            class="bill__button"
            @click="changePublish"
            size="small"
            type="secondary"
            :class="`_${publish.data}`"
            raised
            :disabled="isClosed"
            v-if="publish.visible"
          >
            {{ i18n.t(`accounts.trading.${publish.data}`) }}
          </sdButton>
          <sdButton
            class="bill__button"
            @click="openModal('deposit')"
            size="small"
            type="success"
            raised
            :disabled="isClosed"
            v-if="!BillAccount?.contest || !isDemo"
          >
            {{ i18n.t('start.deposit') }}
          </sdButton>
          <sdButton
            class="bill__button"
            @click="openModal('withdrawal')"
            size="small"
            type="primary"
            raised
            :disabled="isClosed"
            v-if="isDemo && !BillAccount?.contest"
          >
            {{ i18n.t('start.withdrawal') }}
          </sdButton>
          <sdButton
            class="bill__button"
            size="small"
            type="danger"
            @click="openCloseDemo"
            raised
            :disabled="isClosed"
            v-if="BillAccount?.contest && isDemo"
          >
            {{ i18n.t('accounts.trading.close') }}
          </sdButton>
        </div>
      </div>
      <div class="trading__title bills__title">
        <div class="trading__texts">
          <TradingAuth :tradingInfo="BillAccount?.mt5_account_data" />
        </div>
      </div>
      <div class="bill__statuses">
        <Status style="margin-left: 0" :status="BillAccount.contest?.status ?? BillAccount.mt5_account_data.status" />
        <Status
          style="margin-left: 20px"
          status="leverage"
          :text="i18n.t('accounts.trading.published')"
          v-if="publish.data === 'unpublish'"
        />
        <TradingContestTag :contest="BillAccount.contest" />
        <slot name="status" />
      </div>
    </div>
  </sdCards>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

const Status = defineAsyncComponent(() => import('@/components/elements/Status'));
const TradingAuth = defineAsyncComponent(() => import('@/components/elements/TradingAuth.vue'));
const TradingContestTag = defineAsyncComponent(() => import('@/components/elements/TradingContestTag'));
const BillGraphComponent = defineAsyncComponent(() => import('@/components/elements/BillGraphComponent.vue'));

const StartBills = {
  name: 'StartBills',
  components: { Status, TradingAuth, BillGraphComponent, TradingContestTag },
  props: {
    BillAccount: {
      type: Object,
    },
    BillIcon: {
      type: String,
    },
    AccountId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const router = useRouter();

    const passwordVisible = ref({ invest: false, master: false });

    const isDemo = computed(() => props.BillAccount?.mt5_account_data.kind === 'demo');

    const isBlocked = computed(() => props.BillAccount?.mt5_account_data.status !== 'blocked');

    const isClosed = computed(() => props.BillAccount?.mt5_account_data.status === 'closed');

    const publish = computed(() => {
      const modificator = props.BillAccount?.mt5_account_data.modificator;
      return {
        data: modificator && modificator !== 'trade' ? 'unpublish' : 'publish',
        visible: !isDemo.value,
      };
    });
    const openModal = (nameAction) => {
      emit('openModal');
      router.push(`/trading-${nameAction}/${props.AccountId}?trading=real`);
    };

    const changePublish = () => {
      const data = {
        ...props.BillAccount,
        id: props.AccountId,
      };
      emit(`openPublishModal`, {
        ...JSON.parse(JSON.stringify(data)),
      });
    };

    const openCloseDemo = () => {
      const data = { id: props.AccountId, name: props.BillAccount?.mt5_account_data.name };

      emit(`update:closingModal`, {
        visible: true,
        data: JSON.parse(JSON.stringify(data)),
      });
    };

    return {
      passwordVisible,
      i18n,
      isDemo,
      isBlocked,
      publish,
      isClosed,
      openModal,
      changePublish,
      openCloseDemo,
    };
  },
};
export default StartBills;
</script>

<style lang="scss" scoped>
._unpublish {
}
</style>
